import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { CanAuthGuard } from "./core/keycloak/keycloak.guard";

import { routes as errorRoutes } from "./pages/error/error.routes";
import { ErrorPageModule } from "./pages/error/error.module";

import { CartModule } from "./modals/cart/cart.module";
import { CartModalComponent } from "./modals/cart/cart.modal";
import { AddPaymentMethodModule } from "./modals/add-payment-method/add-payment-method.module";
import { AddPaymentMethodPage } from "./modals/add-payment-method/add-payment-method.page";
import { FailedPaymentMethodModule } from "./modals/failed-payment-method/failed-payment-method.module";
import { FailedPaymentMethodPage } from "./modals/failed-payment-method/failed-payment-method.page";
import { PastDueModule } from "./modals/past-due/past-due.module";
import { PastDuePage } from "./modals/past-due/past-due.page";
import { AddVehicleModalPage } from "./modals/add-vehicle/add-vehicle.page";
import { AddVehicleModalModule } from "./modals/add-vehicle/add-vehicle.module";
import { EditAccountInfoPage } from "./modals/edit-account-info/edit-account-info.page";
import { EditAccountInfoModule } from "./modals/edit-account-info/edit-account-info.module";
import { EditVehiclePage } from "./modals/edit-vehicle/edit-vehicle.page";
import { EditVehicleModule } from "./modals/edit-vehicle/edit-vehicle.module";
import { FourohfourComponent } from "./shared/components/error/fourohfour/fourohfour.component";
import { PlansAuthGuard } from "./core/guards/plans-auth.guard";
import { EditVehicleIdentifiersModule } from "./modals/edit-vehicle-identifiers/edit-vehicle-identifiers.module";
import { EditVehicleIdentifiersPage } from "./modals/edit-vehicle-identifiers/edit-vehicle-identifiers.page";
import { VehicleSwapModule } from "./modals/vehicle-swap/vehicle-swap.module";
import { VehicleSwapPage } from "./modals/vehicle-swap/vehicle-swap.page";
import { ParamsGuard } from "./core/guards/query-params.guard";
import { SalesPipelineGuard } from "./core/guards/sales-pipeline.guard";

export const routes: Routes = [
  /**
   * Legacy routes for backwards compatibility
   * home used to be at /home
   * /auth/login was used for finding initial route (initial-route guard does this now)
   * /history used to be at /orders
   */
  {
    path: "home",
    redirectTo: "",
    pathMatch: "full",
  },
  {
    path: "auth/login",
    redirectTo: "",
    pathMatch: "full",
  },
  {
    path: "orders",
    redirectTo: "/history",
    pathMatch: "full",
  },
  /**
   * Stub path for sales pipeline
   */
  {
    path: "sales",
    redirectTo: "",
    pathMatch: "prefix",
  },

  {
    path: "",
    canActivate: [ParamsGuard],
    children: [
      {
        path: "plans",
        loadChildren: () =>
          import("./pages/plans/plans.module").then((m) => m.PlansPageModule),
        canActivate: [PlansAuthGuard],
      },
      {
        path: "info",
        loadChildren: () =>
          import("./pages/info/info.module").then((m) => m.InfoPagesModule),
      },
      {
        path: "error",
        children: errorRoutes,
      },
      {
        path: "account-setup",
        loadChildren: () =>
          import("./pages/account-setup/account-setup.module").then(
            (m) => m.AccountSetupPageModule
          ),
        canActivate: [CanAuthGuard],
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/home/home.module").then((m) => m.HomePageModule),
      },
      {
        path: "employeebenefits",
        loadChildren: () =>
          import(
            "./pages/b2c-employee-benefits/b2c-employee-benefits.module"
          ).then((m) => m.B2bEmployeeBenefitsPageModule),
        canActivate: [CanAuthGuard],
      },
      {
        path: "purchase",
        loadChildren: () =>
          import("./pages/purchase/purchase.module").then(
            (m) => m.PurchasePageModule
          ),
        canActivate: [CanAuthGuard],
      },
      {
        path: "membership",
        loadChildren: () =>
          import("./pages/membership/membership.module").then(
            (m) => m.MembershipPageModule
          ),
        canActivate: [CanAuthGuard],
      },

      {
        path: "history",
        loadChildren: () =>
          import("./pages/history/history.module").then(
            (m) => m.HistoryPageModule
          ),
        canActivate: [CanAuthGuard],
      },
      {
        path: "payment-methods",
        loadChildren: () =>
          import("./pages/payment-methods/payment-methods.module").then(
            (m) => m.PaymentMethodsPageModule
          ),
        canActivate: [CanAuthGuard],
      },
      {
        path: "account",
        loadChildren: () =>
          import("./pages/account/account.page.module").then(
            (m) => m.AccountPageModule
          ),
        canActivate: [CanAuthGuard, SalesPipelineGuard],
      },
      {
        path: "vehicles",
        loadChildren: () =>
          import("./pages/vehicles/vehicles.module").then(
            (m) => m.VehiclesPageModule
          ),
        canActivate: [CanAuthGuard],
      },
      {
        path: "locations",
        loadChildren: () =>
          import("./pages/locations/locations.module").then(
            (m) => m.LocationsPageModule
          ),
      },
      {
        path: "thank-you",
        loadChildren: () =>
          import("./pages/thank-you/thank-you.module").then(
            (m) => m.ThankYouPageModule
          ),
        canActivate: [CanAuthGuard],
      },
      {
        path: "relink",
        loadChildren: () =>
          import("./pages/account-setup/account-setup.module").then(
            (m) => m.AccountSetupPageModule
          ),
        canActivate: [CanAuthGuard],
      },
    ],
  },
  /**
   * Modals
   * Note the modal URLs are prettified by the serializer in modals.serializer.ts
   */
  {
    path: "cart",
    component: CartModalComponent,
    outlet: "modal",
  },
  {
    path: "add-payment-method",
    component: AddPaymentMethodPage,
    outlet: "modal",
  },
  {
    path: "failed-payment-method",
    component: FailedPaymentMethodPage,
    outlet: "modal",
  },
  {
    path: "past-due",
    component: PastDuePage,
    outlet: "modal",
  },
  {
    path: "add-vehicle",
    component: AddVehicleModalPage,
    outlet: "modal",
  },
  {
    path: "edit-account-info",
    component: EditAccountInfoPage,
    outlet: "modal",
  },
  {
    path: "edit-vehicle",
    component: EditVehiclePage,
    outlet: "modal",
  },
  {
    path: "swap-vehicle",
    component: VehicleSwapPage,
    outlet: "modal",
  },
  {
    path: "edit-vehicle-identifier",
    component: EditVehicleIdentifiersPage,
    outlet: "modal",
  },

  /**
   * Redirect for 404.
   */
  { path: "**", pathMatch: "full", component: FourohfourComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),

    // Import non-lazy page modules
    ErrorPageModule,
    CartModule,
    EditAccountInfoModule,
    AddPaymentMethodModule,
    FailedPaymentMethodModule,
    PastDueModule,
    AddVehicleModalModule,
    EditVehicleModule,
    EditVehicleIdentifiersModule,
    VehicleSwapModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
