import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { KeycloakService } from "keycloak-angular";
import { Observable, of, Subscription } from "rxjs";
import { first, map, tap } from "rxjs/operators";
import {
  AccountAndMembershipStatus,
  getAccountInfo,
  selectAccountAndMembershipStatus,
} from "src/app/core/ngrx/myqq";
import { filterSuccessMapToRightValue } from "src/lib/datum-either";
import { isInitial } from "@nll/datum/Datum";

@Injectable({
  providedIn: "root",
})
export class PlansAuthGuard {
  subs: Subscription[] = [];
  constructor(
    private keycloak: KeycloakService,
    private router: Router,
    readonly store$: Store
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    const loggedInValue = this.keycloak.isLoggedIn();
    if (loggedInValue) {
      return this.store$.select(selectAccountAndMembershipStatus).pipe(
        tap((account) => {
          if (isInitial(account)) {
            this.store$.dispatch(getAccountInfo.pending(null));
          }
        }),
        filterSuccessMapToRightValue,
        first(),
        map((accountStatus: AccountAndMembershipStatus) => {
          if (accountStatus.hasAccount) {
            return this.router.createUrlTree(["purchase"], {});
          }
          return this.router.createUrlTree([""], {});
        })
      );
    } else {
      return of(true);
    }
  }
}
