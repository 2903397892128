import { Inject, Injectable } from "@angular/core";
import { UrlTree, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { isSuccess, isInitial, isPending } from "@nll/datum/DatumEither";
import { notNil } from "@qqcw/qqsystem-util";
import { KeycloakService } from "keycloak-angular";
import { interval, Observable, of } from "rxjs";
import {
  catchError,
  delayWhen,
  filter,
  map,
  switchMap,
  take,
} from "rxjs/operators";
import { getParams } from "src/lib/util";
import { getProfile, selectProfile } from "../ngrx/myqq";
import { setSalesPipeline, selectKeycloakInitialized } from "../ngrx/ui";
import { WINDOW } from "../services/window.service";

const profileTimeout = 500; // ms
@Injectable({
  providedIn: "root",
})
export class SalesPipelineGuard {
  constructor(
    readonly store$: Store<any>,
    readonly router: Router,
    readonly keycloak: KeycloakService,
    @Inject(WINDOW) readonly window: Window
  ) {}
  canActivate(): Observable<boolean | UrlTree> {
    const { params } = getParams(window?.location?.search);
    if (params?.zip && params?.sku) {
      this.store$.dispatch(
        setSalesPipeline({
          isSalesPipeline: true,
          origination: params?.origination,
          zipcode: params.zip,
          subscriptionSKU: params.sku,
        })
      );

      return this.store$.select(selectKeycloakInitialized).pipe(
        filter((init) => init),
        switchMap(() => {
          const auth = this.keycloak.isLoggedIn();
          // If authenticated, get the profile to check if account is set up
          if (auth) {
            const profile$ = this.store$.select(selectProfile);
            this.store$.dispatch(getProfile.pending({ track: true }));
            return profile$.pipe(
              filter((r) => !isInitial(r)),
              delayWhen((r) =>
                isPending(r) ? interval(profileTimeout) : interval(0)
              ),
              take(1),
              map((r) => {
                if (isSuccess(r)) {
                  const membership = r.value.right.membership;
                  if (!notNil(membership)) {
                    const searchParam = window?.location?.search;
                    if ((params?.zip && params?.sku) || params?.hasMembership) {
                      return true;
                    }
                    return this.router.createUrlTree(["purchase"], {
                      queryParams: this.parseParams(searchParam),
                    });
                  } else {
                    return this.router.createUrlTree([""], {});
                  }
                }
                return true;
              }),
              catchError(() => {
                return of(this.router.createUrlTree([""], {}));
              })
            );
          } else {
            return of(true);
          }
        })
      );
    }
  }

  parseParams(params: string): { [key: string]: string } {
    const paramObject = {};

    params
      .split("?")[1]
      .split("&")
      .forEach((p) => {
        const [key, value] = p.split("=");
        paramObject[key] = value;
      });

    return paramObject;
  }
}
