import { EMPTY } from "rxjs";
import { catchError, delay, filter, map, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { filterActions } from "src/app/shared/utilities/state/Operators";

import { log } from "../logger";
import { getProfile } from "../myqq";
import { routeChange } from "../router/router.reducers";
import {
  setOrderCreated,
  setSalesPipeline,
  stepChange,
} from "../ui/ui.reducers";

import { pageView } from "./segment.reducers";
import { SegmentService } from "./segment.service";

@Injectable()
export class SegmentChainEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly segment: SegmentService,
    private readonly store$: Store<any>
  ) {}

  readonly routeChange = createEffect(() =>
    this.actions$.pipe(
      filterActions(routeChange),
      delay(0), // pause for a beeat to let the page title update after the route change
      map(({ value }) =>
        pageView({
          path: value.url,
          name: "Navigated",
          properties: value,
        })
      )
    )
  );

  readonly uiStepChange = createEffect(() =>
    this.actions$.pipe(
      filterActions(stepChange),
      map(({ value: { type: name, ...properties } }) =>
        pageView({
          name,
          properties,
        })
      )
    )
  );

  readonly orderCreated = createEffect(
    () =>
      this.actions$.pipe(
        filterActions(setOrderCreated),
        tap((action: any) => {
          if (!!action?.value) {
            this.segment.track({ type: "Order Created", value: action.value });
          }
        })
      ),
    { dispatch: false }
  );

  readonly salesPipeline = createEffect(
    () =>
      this.actions$.pipe(
        filterActions(setSalesPipeline),
        filter((action) => action.value.origination !== "myqq"),
        map((action) => ({
          type:
            (action.value.isSalesPipeline ? "Enter" : "Exit") +
            " Sales Pipeline",
          value: {
            sku: action.value.subscriptionSKU,
            zip: action.value.zipcode,
          },
        })),
        tap({ error: this.reportErrorToLog }),
        catchError(() => EMPTY),
        map((a) => this.segment.track(a))
      ),
    { dispatch: false }
  );

  readonly pageView = createEffect(
    () =>
      this.actions$.pipe(
        filterActions(pageView),
        map(({ value }) => this.segment.pageView(value)),
        tap({ error: this.reportErrorToLog }),
        catchError(() => EMPTY)
      ),
    { dispatch: false }
  );

  readonly identify = createEffect(
    () =>
      this.actions$.pipe(
        filterActions(getProfile.success),
        filter(({ value: { params } }) => params?.track ?? false),
        filter(
          ({
            value: {
              result: { profile },
            },
          }) => !!profile?.info?.qsysAccount
        ),
        map((a) => this.segment.identify(a)),
        tap({ error: this.reportErrorToLog }),
        catchError(() => EMPTY)
      ),
    { dispatch: false }
  );

  private readonly reportErrorToLog = (error: any) => {
    this.store$.dispatch(
      log({
        message: error.msg,
        data: { error },
      })
    );
  };
}
