import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";

import { isInitial, isReplete } from "@nll/datum/Datum";
import { isFailure, isSuccess, map as mapDE } from "@nll/datum/DatumEither";
import { isSome, Option } from "fp-ts/es6/Option";
import { combineLatest, Subscription } from "rxjs";
import { filter, first, map, mergeMap, tap } from "rxjs/operators";
import { Money } from "@qqcw/qqsystem-util";

import {
  addFlockOrder,
  checkPromoCode,
  clearPromoCode,
  clearSubmitOrder,
  selectCalculateOrder,
  selectPaymentMethods,
  selectSubmitOrder,
  submitOrder,
  clearOrderProgress,
  selectMySubscription,
  selectPriceTable,
  selectModifySubscriptionPlan,
  clearModifySubscriptionPlan,
  selectPendingOrder,
  getMyPaymentMethods,
  addMembershipOrder,
  selectMembershipPurchase,
  clearMembershipPurchase,
  selectVehicles,
  getVehicles,
  getPriceTable,
  getMySubscriptions,
  selectPromoDetails,
  emptyPromoDetails,
  setVehiclesOnOrder,
  selectCompanyWidePromo,
  clearSubmitOrderAndGenerateNewOrderId,
} from "src/app/core/ngrx/myqq";
import {
  PostCalculateOrderResponse,
  OrderTypesEnum,
  SubscriptionDetail,
  CustomerPaymentMethod,
  Order,
  OrderMarkDown,
  PromoDetails,
  RegionMenu,
  SubscriptionPlan,
  PostSubmitOrderResponse,
  Vehicle,
  AppName,
} from "src/app/core/services/myqq";

import {
  OrderCreated,
  OrderCreatedItem,
  SalesPipeline,
  clearUIPromoCode,
  selectDismissAddVehiclePromptInCart,
  selectSalesPipeline,
  setDismissAddVehiclePromptInCart,
  setSalesPipeline,
  setOrderCreated,
} from "src/app/core/ngrx/ui";
import {
  combineS,
  filterSuccessMapToRightValue,
  withDatumEither,
} from "src/lib/datum-either";
import { environment } from "src/environments/environment";
import { errors } from "src/app/core/services/myqq/myqq.errors";
import { getSpecificWashLevel } from "src/lib/util";
import { MyQQOrderChainEffects } from "src/app/core/ngrx/myqq/cart/myqq-cart.chains";
@Component({
  templateUrl: "./cart.modal.html",
  styleUrls: ["./cart.modal.scss"],
})
export class CartModalComponent implements OnDestroy, OnInit {
  constructor(
    private readonly store$: Store<any>,
    private chains: MyQQOrderChainEffects,
    private readonly router: Router
  ) {}

  header = "Cart";

  showErrorComponent = true;
  calculateOrderError = null;
  rxjsSubs = [] as Subscription[];
  invalidCouponErr = errors.myqqApi.errInvalidCoupon;
  initialPromoCode: string | null = null;

  readonly phoneNumber = environment.supportNumber;
  readonly membershipPurchase$ = this.store$.select(selectMembershipPurchase);
  readonly pendingOrder$ = this.store$.select(selectPendingOrder);
  readonly submitOrder$ = this.store$.select(selectSubmitOrder);
  readonly promoDetails$ = this.pendingOrder$.pipe(
    mergeMap((o) =>
      combineLatest([
        this.store$.select(selectPromoDetails),
        this.store$.select(selectCompanyWidePromo),
      ]).pipe(
        map(([specificPromoDetail, companyWidePromo]) => {
          if (
            isSuccess(specificPromoDetail) &&
            !!specificPromoDetail.value.right.code
          ) {
            return [specificPromoDetail, o];
          } else if (isSuccess(companyWidePromo)) {
            return [companyWidePromo, o];
          } else {
            return [{} as PromoDetails, o];
          }
        })
      )
    ),
    map(([p, o]) => {
      const promoDetails = p as PromoDetails;
      const order = o as Option<Order>;
      // If the promo code does not apply to this order type, treat it like it doesn't exist
      if (
        isSome(order) &&
        promoDetails.autoApplyOrderTypes?.length &&
        !promoDetails.autoApplyOrderTypes.includes(order.value.orderType)
      ) {
        return {} as PromoDetails;
      }
      if (
        isSome(order) &&
        order.value?.markDowns?.length > 0 &&
        !this.initialPromoCode
      ) {
        this.initialPromoCode = order.value?.markDowns[0].serialNo;
      }
      return promoDetails;
    })
  );
  readonly vehicles$ = this.store$.select(selectVehicles).pipe(
    tap((vehicles) => {
      if (isInitial(vehicles)) {
        this.store$.dispatch(getVehicles.pending(null));
      }
    }),
    filterSuccessMapToRightValue
  );

  readonly calculateOrder$ = this.store$
    .select(selectCalculateOrder)
    .pipe(
      tap((calculatedOrder) => {
        if (isFailure(calculatedOrder)) {
          this.calculateOrderError = (calculatedOrder.value
            ?.left as any)?.error?.message;
          this.handleCouponError(calculatedOrder);
        } else {
          this.calculateOrderError = null;
        }
      })
    )
    .pipe(filterSuccessMapToRightValue);

  readonly newSubscriptionPlan$ = this.store$
    .select(selectMembershipPurchase)
    .pipe(
      map((membershipPurchase) =>
        isSome(membershipPurchase) ? membershipPurchase?.value?.wash : null
      ),
      withDatumEither()
    )
    .pipe(filterSuccessMapToRightValue);

  readonly contextData$ = combineS({
    currentSubscription: this.store$
      .select(selectMySubscription)
      .pipe(
        map(
          mapDE((subscription) =>
            isSome(subscription) ? subscription.value : null
          )
        )
      ),

    paymentMethods: this.store$.select(selectPaymentMethods).pipe(
      tap((paymentMethods) => {
        if (isInitial(paymentMethods)) {
          this.store$.dispatch(getMyPaymentMethods.pending());
        }
      })
    ),

    modifySubscriptionPlan: this.store$
      .select(selectModifySubscriptionPlan)
      .pipe(withDatumEither()),

    priceTable: this.store$.select(selectPriceTable),

    salesPipeline: this.store$
      .select(selectSalesPipeline)
      .pipe(withDatumEither()),
  }).pipe(
    tap((contextData) => {
      if (isFailure(contextData) && isReplete(contextData)) {
        this.handleCouponError(contextData);
      }
    })
  );

  readonly handleCouponError = (data: any) => {
    if (data) {
      const errorMessage = (data.value?.left as any)?.error?.message;
      const invalidCoupon = errorMessage === this.invalidCouponErr;
      if (invalidCoupon) {
        // the first time the cart loads, promodetails observable hasn't emitted
        // because there isn't a subscription to the observable yet, so initialPromoCode is null.
        // dirty work around to this timing issue.
        // VAN TODO -WRITE TEST FOR THIS CASE
        this.rxjsSubs.push(this.promoDetails$.pipe(first()).subscribe());
        // In case where promo is auto-applied and invalid, we need to remove it.
        // do a check so we can show the invalid message in the promo component
        if (this.initialPromoCode) {
          this.handleTryPromo(this.initialPromoCode);
          this.initialPromoCode = null;
        }
        this.handleClearPromoCode();
      }
    }
  };

  readonly getCurrentSubscriptionPlan = ({
    currentSubscription,
    priceTable,
  }): SubscriptionPlan => {
    return getSpecificWashLevel(
      currentSubscription?.washLevelId,
      priceTable?.plans
    );
  };
  private readonly submitOrderSub = this.submitOrder$
    .pipe(
      first(isSuccess),
      tap((order: any) => {
        const orderCreated: OrderCreated = {
          account_id: order?.value?.right?.customerId,
          app_name: AppName.myQQ,
          discount: order?.value?.right?.totalMarkDowns,
          tax: order?.value?.right?.totalTax,
          revenue: order?.value?.right?.orderTotal,
          order_id: order?.value?.right?.orderId,
          order_items: order?.value?.right?.items.map(
            (item: any) =>
              ({
                sku: item.sku,
                item_name: item.name,
                quantity: item.orderedQty,
                unit_price: item.unitPrice,
              } as OrderCreatedItem)
          ),
        };

        this.store$.dispatch(setOrderCreated(orderCreated));
        console.log("orderCreated", orderCreated);
      })
    )
    .subscribe((order: PostSubmitOrderResponse) => this.handleSuccess(order));

  private readonly pendingOrderSub = this.pendingOrder$
    .pipe(
      filter(isSome),
      map((order) => {
        switch (order.value.orderType) {
          case OrderTypesEnum.NEWMEMBERSHIP:
            return "Shopping Cart";
          case OrderTypesEnum.ADDMEMBERSHIP:
            return "Add Vehicle To Your Membership";
          case OrderTypesEnum.REMOVESUBVEHICLE:
            return "Deactivate Vehicle From Membership";
          case OrderTypesEnum.UPGRADESUBSCRIPTION:
          case OrderTypesEnum.DOWNGRADESUBSCRIPTION:
            return "Change Your Membership";
          default:
            return "Cart";
        }
      })
    )
    .subscribe((cartHeader) => {
      this.header = cartHeader;
    });

  readonly getActionText = (order: PostCalculateOrderResponse) => {
    if (Money.greaterThan(order?.orderTotal, 0)) {
      return "Purchase";
    }

    return "Confirm";
  };

  ngOnInit() {
    this.rxjsSubs.push(
      combineLatest([
        this.store$.select(selectDismissAddVehiclePromptInCart),
        this.membershipPurchase$.pipe(
          filter(isSome),
          map((de) => de.value)
        ),
      ])
        .pipe(first())
        .subscribe(([dismissPrompt, membership]) => {
          if (
            !dismissPrompt &&
            (!membership.vehicles || membership.vehicles?.length === 0)
          ) {
            this.store$.dispatch(setDismissAddVehiclePromptInCart(true));
            this.handleCreateVehicle();
          }
        })
    );
  }

  ngOnDestroy() {
    this.store$.dispatch(clearSubmitOrder(null));
    this.submitOrderSub?.unsubscribe();
    this.pendingOrderSub?.unsubscribe();
    this.rxjsSubs.forEach((sub) => sub.unsubscribe());
    this.chains.deletedPromo = false;
  }

  handleCreatePaymentMethod() {
    this.router.navigate([{ outlets: { modal: "add-payment-method" } }]);
  }

  handleCreateVehicle() {
    this.router.navigate([{ outlets: { modal: "add-vehicle" } }]);
  }

  handleClearPromoCode() {
    this.chains.deletedPromo = true;
    this.store$.dispatch(clearPromoCode(null));
    this.initialPromoCode = null;
  }

  handleTryPromo(code: string) {
    if (code) {
      this.store$.dispatch(checkPromoCode.pending(code));
    }
  }

  handleRecalculateOrderWithVehicles(
    orderVehicles: Vehicle[],
    { orderType, markDowns },
    vehicles: Vehicle[],
    newSubscriptionPlan: SubscriptionPlan,
    {
      currentSubscription,
      modifySubscriptionPlan,
      priceTable,
    }: {
      currentSubscription?: SubscriptionDetail;
      modifySubscriptionPlan: SubscriptionPlan;
      priceTable: RegionMenu;
    }
  ) {
    let promo;

    const vehiclesOnSubscriptionAndOrder =
      vehicles.filter((v) => v.hasMembership)?.length + orderVehicles.length;
    if (markDowns && markDowns[0] && markDowns[0].serialNo) {
      promo = markDowns[0].serialNo;
    }
    if (
      orderVehicles.length > 0 &&
      ((orderType === OrderTypesEnum.NEWMEMBERSHIP &&
        orderVehicles.length <= environment.limits.maxVehiclesOnSubscription) ||
        (orderType === OrderTypesEnum.ADDMEMBERSHIP &&
          vehiclesOnSubscriptionAndOrder <=
            environment.limits.maxVehiclesOnSubscription))
    ) {
      this.store$.dispatch(clearOrderProgress(null));
      switch (orderType) {
        case OrderTypesEnum.ADDMEMBERSHIP: {
          const currentSubscriptionPlan: SubscriptionPlan = getSpecificWashLevel(
            currentSubscription.washLevelId,
            priceTable.plans
          );
          this.store$.dispatch(
            addFlockOrder({
              subscriptionPlan:
                modifySubscriptionPlan || currentSubscriptionPlan,
              vehicles: orderVehicles,
              promo,
            })
          );
          break;
        }
        case OrderTypesEnum.NEWMEMBERSHIP: {
          this.store$.dispatch(
            addMembershipOrder({
              wash: newSubscriptionPlan,
              vehicles: orderVehicles,
              promo,
            })
          );
          break;
        }
      }
    }
  }

  handleSubmitOrder(
    order: PostCalculateOrderResponse,
    paymentMethods: CustomerPaymentMethod[],
    pendingOrder: PostCalculateOrderResponse,
    salesPipeline?: SalesPipeline
  ) {
    // Quick and dirty fix for applying promo code from calculated order to pending order
    let markdowns = [] as OrderMarkDown[];
    if (pendingOrder.markDowns?.length > 0) {
      markdowns = pendingOrder.markDowns;
    } else {
      // Calclated order will have one markdown per item in the order that the markdown applies to, but
      // order submission object must have only one markdown listed in payload
      const calculatedMarkdowns = new Set(
        order.markDowns.map((markdown) => markdown.serialNo)
      );
      if (calculatedMarkdowns.size === 1) {
        const [serialNo] = calculatedMarkdowns;
        markdowns = [{ serialNo: serialNo }];
      }
    }

    const newOrder: PostCalculateOrderResponse = {
      orderType: order.orderType,
      items: [...pendingOrder.items],
      submissionAt: order.submissionAt,
      orderId: order.orderId,
      customerId: order.customerId,
      markDowns: markdowns,
      payments: [
        ...paymentMethods.map(({ id }) => ({
          paymentId: id,
        })),
      ],
      createReasonCode:
        order.orderType === OrderTypesEnum.REMOVESUBVEHICLE
          ? order.createReasonCode
          : null,
    };

    this.store$.dispatch(submitOrder.pending(newOrder));

    // If this was a sales pipeline order, they have completed the flow
    // Setting this to false triggers a segment tracking event.
    if (salesPipeline?.isSalesPipeline) {
      this.store$.dispatch(
        setSalesPipeline({
          isSalesPipeline: false,
          origination: salesPipeline.origination,
        })
      );
    }
  }

  handleSuccess(order: PostCalculateOrderResponse) {
    this.showErrorComponent = false;
    if (!!order.totalMarkDowns && order.totalMarkDowns != "0") {
      this.promoDetails$.pipe(
        filter((promo) => !!promo.code),
        map(() => {
          this.store$.dispatch(emptyPromoDetails(null));
          this.store$.dispatch(clearUIPromoCode(null));
        })
      );
    }
    this.store$.dispatch(clearOrderProgress(null));
    this.store$.dispatch(clearModifySubscriptionPlan(null));
    this.store$.dispatch(clearMembershipPurchase(null));
    this.store$.dispatch(setVehiclesOnOrder([]));
    this.closeModal(order);
  }

  closeModal(order) {
    this.router.navigate([{ outlets: { primary: "thank-you", modal: null } }], {
      state: {
        orderType: order?.value?.right?.orderType,
        showSubscriptionInfo: true,
      },
    });
  }
  handleResubmitOrder() {
    this.store$.dispatch(clearSubmitOrderAndGenerateNewOrderId(null));
  }

  handleGetData() {
    this.store$.dispatch(getMyPaymentMethods.pending());
    this.store$.dispatch(getVehicles.pending(null));
    this.store$.dispatch(getPriceTable.pending(null));
    this.store$.dispatch(getMySubscriptions.pending(null));
    // handle case when calculateOrder errors due to bad coupon/bad vehicles
    this.store$.dispatch(clearOrderProgress(null));
    this.router.navigate([{ outlets: { modal: null } }]);
  }
}
