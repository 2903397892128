import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { KeycloakService } from "keycloak-angular";

import { getParams } from "src/lib/util";

import {
  setExistingMembership,
  setUIPromoCode,
  setB2cSignUpDetails,
  setSalesPipeline,
} from "../ngrx/ui";
import { WINDOW } from "../services/window.service";

@Injectable({
  providedIn: "root",
})
export class ParamsGuard {
  constructor(
    readonly store$: Store<any>,
    readonly router: Router,
    readonly keycloak: KeycloakService,
    @Inject(WINDOW) readonly window: Window
  ) {}
  canActivate(): boolean {
    const { params } = getParams(window?.location?.search);

    // Set promo code if it exists.
    if (params?.promo) {
      this.store$.dispatch(setUIPromoCode(params.promo));
    }
    // Set salespipeline flag and details if user is coming from sales pipeline if
    // SKU and zip are present in query params.

    if (params?.hasMembership) {
      this.store$.dispatch(
        setExistingMembership({
          hasMembership: true,
        })
      );
    }

    // Set b2c code if it exists.
    if (!!params) {
      let email = "";
      try {
        email = decodeURIComponent(params.email);
      } catch (e) {
        email = params.email;
      }
      this.store$.dispatch(
        setB2cSignUpDetails({
          benefitcode: params.benefitcode,
          email: email,
        })
      );
    }
    if (params?.zip && params?.sku) {
      this.store$.dispatch(
        setSalesPipeline({
          isSalesPipeline: true,
          origination: params?.origination,
          zipcode: params.zip,
          subscriptionSKU: params.sku,
        })
      );
    }
    return true;
  }

  parseParams(params: string): { [key: string]: string } {
    const paramObject = {};

    params
      .split("?")[1]
      .split("&")
      .forEach((p) => {
        const [key, value] = p.split("=");
        paramObject[key] = value;
      });

    return paramObject;
  }
}
