<div class="fld-col fld-dsk-row flg-7">
  <div class="fld-col vw-p100">
    <p class="ta-l pwx-4">
      Please provide your credit card or debit card information to proceed.
    </p>
    <p *ngIf="description" class="ta-c pwx-4">{{ description }}</p>
    <p *ngIf="overdueAmount" class="ta-c pwx-4 pwb-4">
      Once this payment method is added, your account will be billed for the
      overdue amount of <strong>${{ overdueAmount }}</strong
      >.
    </p>

    <section class="fld-col flg-4 pwa-5">
      <div id="stripe-form-wrapper">
        <mat-form-field id="stripe-form">
          <mat-label>Card</mat-label>
          <myqq-stripe-card
            tabindex="0"
            matStripe
            [formControl]="card"
            (change)="handleChange($event)"
          ></myqq-stripe-card>
        </mat-form-field>
        <p
          class="form-error cf-error"
          *ngIf="(form.touched || form.dirty) && (!card || cardError)"
        >
          {{ cardError || "Please enter a valid credit card number" }}
        </p>
      </div>

      <form
        [formGroup]="form"
        id="name-and-country-form"
        class="fld-col flg-4"
        (keydown.enter)="$event.preventDefault()"
        (keyup.enter)="card && !cardError && form.valid && submit.emit()"
      >
        <mat-form-field>
          <mat-label>Name On Card</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
        <p
          class="form-error cf-error"
          *ngIf="form.dirty && form.get('name').invalid"
        >
          Please enter your name as it appears on the card
        </p>
        <mat-form-field>
          <mat-label>Country</mat-label>
          <select matNativeControl formControlName="address_country">
            <option value="US">United States</option>
            <option value="CA">Canada</option>
          </select>
        </mat-form-field>
      </form>
    </section>
  </div>
  <div class="ds-dsk-only mwl-6 mwt-7 vw-p40">
    <p class="fs-d1">We accept major credit cards</p>
    <img
      class="cc-logo"
      mat-card-image
      src="{{ cdnHost }}/img/visalogo.svg"
      alt="visa card logo"
    />
    <img
      class="cc-logo"
      mat-card-image
      src="{{ cdnHost }}/img/mastercardlogo.svg"
      alt="mastercard logo"
    />
    <img
      class="cc-logo"
      mat-card-image
      src="{{ cdnHost }}/img/amexlogo.svg"
      alt="amex logo"
    />
    <img
      class="cc-logo"
      mat-card-image
      src="{{ cdnHost }}/img/discoverlogo.svg"
      alt="amex logo"
    />
  </div>
</div>
